<template>
  <span style="font-size: 28px;font-weight: 900; font-family: 'Roboto',serif">
      {{ time }}
  </span>
</template>

<script>
import dayjs from 'dayjs'

function showTime() {
  const date = dayjs()

  return date.format('DD.MM.YYYY HH:mm:ss')
}

// function showDate(){
//   const date =dayjs()
//   return date.format('dddd')
// }

export default {
  name: 'TimeDisplay',
  data: function () {
    return {
      time: '',
      date:''
    }
  },
  created() {
    setInterval(() => {
      this.time = showTime()
    }, 1000)
  }
}
</script>

<style scoped>

</style>
