<template>
  <v-app>
    <v-app-bar
        app
        dark
        dense
        flat
    >
      <v-app-bar-nav-icon class="ml-2">
        <v-img src="./assets/aadenLogo.png" width="50"></v-img>
      </v-app-bar-nav-icon>
      <v-app-bar-title>火锅专用备菜系统v0.0.2</v-app-bar-title>
      <v-spacer/>

      <time-display/>
      <v-toolbar-items>
<!--        <v-btn @click="changeTo('EN')" v-if="Config.lang.toUpperCase()==='ZH'">English</v-btn>-->
<!--        <v-btn @click="changeTo('ZH')" v-else>中文</v-btn>-->
        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="300"
            :max-height="600"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs"
                   v-on="on"
                   icon>
              <v-icon>
                mdi-cog-outline
              </v-icon>
            </v-btn>
          </template>
          <v-card color="white">
            <v-list dense>
              <template v-for="key in NeededKeys">
                <v-list-item dense :key="'config'+key">
                  <v-list-item-title>{{ key }}</v-list-item-title>
                  <template v-if="typeof Config[key]==='boolean'">
                    <v-list-item-action>
                      <v-switch v-model="Config[key]" color="purple"></v-switch>
                    </v-list-item-action>
                  </template>
                  <template v-else>
                    <v-list-item-action>
                      <v-text-field v-model="Config[key]" color="purple"></v-text-field>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </template>
            </v-list>

            <v-card-actions>
              <v-btn text @click="hardReload">Clear</v-btn>
              <v-spacer></v-spacer>
              <v-btn text @click="menu = false">Cancel</v-btn>
              <v-btn color="primary" text @click="useCurrentConfig">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>

    <v-main app>

      <div class="pa-1">
        <div style="display: grid; grid-gap: 4px"
             class="mb-1"
             :style="{
              gridTemplateColumns:'repeat('+Config.columnCount+',minmax(0,1fr))',
              }"
        >
          <template v-for="dish in dishes">

            <v-card elevation="0" :key="dish.code"
                    tile dark
                    style="font-size: xx-large;"
                    height="220px"
                    class="d-flex flex-column pa-3"
                    :color="dish.dCacheColor+' darken-4'"
            >
              <div style="font-size: 2.8rem;
              max-lines: 2;
              text-overflow: ellipsis;
overflow:hidden;
display:-webkit-box;
-webkit-line-clamp:2;
-webkit-box-orient:vertical;
" class="font-weight-black text-capitalize">
                {{ dish.name }}
              </div>
              <div style="position:absolute;left: 12px;bottom: 12px">
                {{ dish.code }}
              </div>
              <div style="
             position:absolute;right: 16px;bottom: 0; "
                   class="font-family: Ebrima-Bold">
                <span style="font-size: 4rem">{{ dish.currentAmount }}</span>/
                <span style="font-size: 3rem">{{ dish.threshold }}</span>
              </div>
            </v-card>


          </template>
        </div>

      </div>

    </v-main>
  </v-app>
</template>

<script>

import {showCurrentDCacheInfo} from "@/api/api";
import GlobalConfig, {hardReload, NeededKeys, useCurrentConfig} from "@/modules/LocalGlobalSettings";
import TimeDisplay from "@/components/TimeDisplay";
import {orderBy} from "lodash-es";
// import TimeDisplay from '@/components/TimeDisplay'


export default {
  name: 'App',
  data: () => ({
    dishes: [],
    timer: null,
    time: '',
    date: '',
    menu: null,
    NeededKeys,
    Config: GlobalConfig
  }),
  components: {
    TimeDisplay
    // TimeDisplay
  },
  methods: {
    useCurrentConfig,
    hardReload,
    async loadDishes() {
      this.dishes = orderBy(await showCurrentDCacheInfo(), ['printGroupId', 'displaySort', 'code'], ['asc', 'asc', 'asc'])
      console.log(this.dishes)
    },
    changeTo(target) {
      GlobalConfig.lang = target
    }


  },

  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
    // prevent memory leak
    // clearInterval(this.interval)
  },

  mounted() {
    this.loadDishes()
    this.timer = setInterval(this.loadDishes, 5000)
    // this.setTime()
  },

}
</script>

<style>

.line1 {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

#app {
  font-family: 'Noto Serif SC', serif !important;

}

</style>


