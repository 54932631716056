import hillo from "hillo";
import GlobalConfig from "@/modules/LocalGlobalSettings";

export async function showCurrentDCacheInfo() {
    return (await hillo.get('DCache.php?op=showCurrentDCacheInfo', {
        lang: GlobalConfig.lang.toLowerCase(),
        limit: 20
    })).content
}



